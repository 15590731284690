import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';

import './signUp.scss';
import Loading from '../../components/loading';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
import camera from '../../assets/images/camara.svg';
import threearrow from '../../assets/images/3arrow.svg';
import Header from '../Header';

const GENDERS = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
];

const SignUp = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [authBearer, setAuthBearer] = useState(false);
  const [stravaUser, setStravaUser] = useState(false);
  const [gender, setGender] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [isGuest, setIsGuest] = useState(null);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const authBearer = query.get('bearer');
    const stravaRefreshToken = query.get('refreshToken');
    const guestEmail = query.get('email');
    if (authBearer) {
      setAuthBearer(authBearer);
      setRefreshToken(stravaRefreshToken);
      setIsGuest(false);
    } else {
      setEmail(guestEmail);
      setIsGuest(true);
    }
  }, []);

  useEffect(() => {
    if (authBearer) {
      getStravaAthlete();
    }
  }, [authBearer]);

  useEffect(() => {
    if (email) {
      isValidEmail();
    }
  }, [email]);

  useEffect(() => {
    // if (stravaUser) {
    //   setName(stravaUser.firstname);
    //   setLastName(stravaUser.lastname);
    //   setGender(stravaUser.sex === 'M' ? 'male' : 'female');
    // }
  }, [stravaUser]);

  // use current access token to call all activities
  const getStravaAthlete = async () => {
    setShowLoading(true);
    const url = new URL(`${config.stravaApiUrl}/athlete`);
    const headers = {
      Authorization: `Bearer ${authBearer}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthlete = await handleResponse(response);
      setStravaUser(stravaAthlete);
    } catch (error) {
      console.log('error', error);
    }
  };

  const isValidEmail = () => {
    if (!email) {
      setValidEmail(false);
      return;
    }
    let newValidEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      newValidEmail = true;
    }
    setValidEmail(newValidEmail);
  };

  const createGuest = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/sign-up-guest`);
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      fullName,
      email,
      gender,
    };

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const guest = await handleResponse(response);
      if (guest && guest.token) {
        localStorage.setItem(
          'athleteLogged3',
          JSON.stringify({
            athlete: { ...guest.guest, isGuest: true },
            token: guest.token,
          })
        );
        window.location.replace('/ranking');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const save = () => {
    if (isGuest) {
      createGuest();
    } else {
      // updateAthlete();
    }
  };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  return (
    <div className='sign-up'>
      {showLoading && <Loading />}
      <Header signUp />
      <div className='photo-section'>
        <div className='photo-background' />
        <div className='photo-container'>
          <div className='photo-area'>
            <img src={camera} alt='camera' className='upload-photo' />
          </div>
        </div>
      </div>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12}>
            <div className='edit-title-container'>
              <div className='edit-title'>
                INFORMACIÓN
                <br /> PERSONAL
              </div>
              <img className='three-arrow' src={threearrow} alt='logo' />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xsOffset={1} xs={10}>
        <Row>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setFullName)}
                value={fullName}
                id='fullName'
                label='Nombre Completo'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setEmail)}
                value={email}
                disabled
                id='email'
                label='Email'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='gender-label'>Genero</InputLabel>
              <Select
                labelId='gender-label'
                id='gender'
                onChange={changeInput(setGender)}
                value={gender}
              >
                {GENDERS.map((selectGender) => {
                  return (
                    <MenuItem
                      key={selectGender.value}
                      value={selectGender.value}
                    >
                      {selectGender.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>

          <button
            onClick={save}
            className='submit-button'
            disabled={!(!!fullName && !!email && !!gender && !!validEmail)}
          >
            Guardar
          </button>
        </Row>
      </Col>
    </div>
  );
};

export default SignUp;

// import React, { useState, useEffect } from 'react';
// import { TextField, FormControl } from '@material-ui/core';
// import moment from 'moment';

// import './signUp.scss';
// import Loading from '../../components/loading';
// import Footer from '../../components/Footer';
// import config from '../../config';
// import { handleResponse, serializeQuery } from '../../utils/handleResponse';
// import logo from '../../assets/images/logocountry.png';
// import bikelogin from '../../assets/images/bikelogin.svg';
// import threearrow from '../../assets/images/3arrow.svg';
// import idcard from '../../assets/images/idcard.svg';
// import emailicon from '../../assets/images/emailicon.svg';
// import idcarderror from '../../assets/images/idcarderror.svg';

// const SignUp = ({ history }) => {
//   const [showLoading, setShowLoading] = useState(false);
//   const [stravaUser, setStravaUser] = useState(false);
//   const [identificationCard, setIdentificationCard] = useState('');
//   const [existsError, setExistsError] = useState(null);
//   const [isGuest, setIsGuest] = useState(null);
//   const [email, setEmail] = useState('');
//   const [validEmail, setValidEmail] = useState(true);
//   const [innerHeight, setInnerHeight] = useState(false);
//   const [access, setAccess] = useState(false);

//   useEffect(() => {
//     setAccess(JSON.parse(localStorage.getItem('access')));
//     setInnerHeight(window.innerHeight);
//     window.addEventListener('resize', () => {
//       setInnerHeight(window.innerHeight);
//     });
//   }, []);

//   useEffect(() => {
//     if (access) {
//       console.log('access', access);
//       setStravaUser(access.athlete);
//     }
//   }, [access]);

//   useEffect(() => {
//     if (email) {
//       isValidEmail();
//     }
//   }, [email]);

//   const openIG = () => {
//     window.open('https://www.instagram.com/thenarinochallenge/', '_blank');
//   };

//   const sendGuest = () => {
//     createGuest();
//   };

//   const goUpdate = async (athlete, prDate = null, prTime = null) => {
//     localStorage.setItem(
//       'athleteLogged2',
//       JSON.stringify({
//         athlete: { ...athlete.athlete, isGuest: false },
//         token: athlete.token,
//       })
//     );
//     await updateAthlete(prDate, prTime);
//   };

//   const getStravaSegmentEfforts = async (athlete, segmentId) => {
//     setShowLoading(true);
//     const url = new URL(
//       `${config.stravaApiUrl}/segment_efforts?segment_id=${segmentId}`
//     );
//     const headers = {
//       Authorization: `Bearer ${access.access_token}`,
//     };
//     const requestOptions = {
//       method: 'GET',
//       headers,
//     };
//     const response = await fetch(url, requestOptions);
//     setShowLoading(false);
//     try {
//       const stravaAthleteEfforts = await handleResponse(response);
//       // const stravaAthleteEffortFound = stravaAthleteEfforts.find(
//       //   (stravaAthleteEffort) => `${stravaAthleteEffort.id}`.length === 11
//       // );
//       if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
//         getStravaSegmentEffort(athlete, access, stravaAthleteEfforts[0].id);
//       } else {
//         await goUpdate(athlete);
//       }
//     } catch (error) {
//       console.log('error', error);
//       await goUpdate(athlete);
//     }
//   };

//   const getStravaSegmentEffort = async (athlete, effortId) => {
//     setShowLoading(true);
//     const url = new URL(`${config.stravaApiUrl}/segment_efforts/${effortId}`);
//     const headers = {
//       Authorization: `Bearer ${access.access_token}`,
//     };
//     const requestOptions = {
//       method: 'GET',
//       headers,
//     };
//     const response = await fetch(url, requestOptions);

//     setShowLoading(false);
//     try {
//       const stravaAthleteEffort = await handleResponse(response);
//       await goUpdate(
//         athlete,
//         stravaAthleteEffort.athlete_segment_stats.pr_date,
//         stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time
//       );
//     } catch (error) {
//       await goUpdate(athlete);
//       console.log('error', error);
//     }
//   };

//   const sendAthlete = async () => {
//     const athlete = await getAthlete();

//     if (athlete && athlete.token) {
//       // if (!athlete.prTime) {
//       //   await getStravaSegmentEfforts(athlete, athlete.athlete.segmentStravaId);
//       // } else {
//       await goUpdate(athlete);
//       // }
//     } else {
//       setExistsError(true);
//     }
//   };

//   const getAthlete = async () => {
//     setShowLoading(true);
//     const url = new URL(`${config.apiUrl}/auth/identification-card`);
//     const requestOptions = {
//       method: 'GET',
//     };
//     const params = {
//       identificationCard,
//     };
//     url.search = serializeQuery(params);
//     const response = await fetch(url, requestOptions);
//     setShowLoading(false);
//     try {
//       const body = await handleResponse(response);
//       return body;
//     } catch (error) {
//       console.log('error', error);
//     }
//   };

//   // const getStravaAthlete = async () => {
//   //   setShowLoading(true);
//   //   const url = new URL(`${config.stravaApiUrl}/athlete`);
//   //   const headers = {
//   //     Authorization: `Bearer ${access.access_token}`,
//   //   };
//   //   const requestOptions = {
//   //     method: 'GET',
//   //     headers,
//   //   };
//   //   const response = await fetch(url, requestOptions);
//   //   setShowLoading(false);
//   //   try {
//   //     const stravaAthlete = await handleResponse(response);
//   //     setStravaUser(stravaAthlete);
//   //   } catch (error) {
//   //     console.log('error', error);
//   //   }
//   // };

//   const isValidEmail = () => {
//     if (!email) {
//       setValidEmail(false);
//       return;
//     }
//     let newValidEmail = false;
//     if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
//       newValidEmail = true;
//     }
//     setValidEmail(newValidEmail);
//   };

//   const createGuest = async () => {
//     setShowLoading(true);
//     const url = new URL(`${config.apiUrl}/auth/sign-up-guest`);
//     const headers = {
//       'Content-Type': 'application/json',
//     };
//     const body = {
//       photoUrl: stravaUser.profile,
//       fullName: `${stravaUser.firstname} ${stravaUser.lastname}`,
//       email,
//       gender: stravaUser.sex === 'F' ? 'female' : 'male',
//       cityName: stravaUser.city,
//       stravaId: stravaUser.id,
//     };

//     const requestOptions = {
//       method: 'POST',
//       headers,
//       body: JSON.stringify(body),
//     };
//     const response = await fetch(url, requestOptions);
//     setShowLoading(false);
//     try {
//       const guest = await handleResponse(response);
//       if (guest && guest.token) {
//         localStorage.setItem(
//           'athleteLogged2',
//           JSON.stringify({
//             athlete: { ...guest.guest, isGuest: true },
//             token: guest.token,
//           })
//         );
//         history.replace('/ranking');
//       }
//     } catch (error) {
//       console.log('error', error);
//     }
//   };

//   const updateAthlete = async (prDate = null, prTime = null) => {
//     setShowLoading(true);
//     const athlete = JSON.parse(localStorage.getItem('athleteLogged2'));
//     const url = new URL(`${config.apiUrl}/api/athletes/${athlete.athlete._id}`);
//     const headers = {
//       Authorization: `Bearer ${athlete.token}`,
//       'Content-Type': 'application/json',
//     };
//     const body = {
//       photoUrl: stravaUser.profile,
//       stravaId: stravaUser.id,
//       refreshToken: access.refresh_token,
//     };

//     if (prDate && prTime) {
//       body['prDate'] = prDate
//         ? moment(prDate, 'YYYY-MM-DD').toISOString()
//         : null;
//       body['prTime'] = prTime || null;
//     }

//     // prTime

//     const requestOptions = {
//       method: 'PUT',
//       headers,
//       body: JSON.stringify(body),
//     };
//     const response = await fetch(url, requestOptions);
//     setShowLoading(false);
//     try {
//       const updatedAthlete = await handleResponse(response);
//       localStorage.setItem(
//         'athleteLogged2',
//         JSON.stringify({
//           athlete: { ...updatedAthlete, isGuest: false },
//           token: athlete.token,
//         })
//       );
//       history.replace('/ranking');
//     } catch (error) {
//       console.log('error', error);
//     }
//   };

//   const changeInput = (inputSet) => (event) => {
//     inputSet(event.target.value);
//   };

//   return (
//     <div className='sign-up'>
//       {showLoading && <Loading />}
//       <div className='login_logo' />
//       <div className='steps-container'>
//         {!existsError && isGuest === null && (
//           <>
//             <img className='bike_log' src={bikelogin} alt='logo' />
//             <div className='sign-up-title'>¿ERES PARTICIPANTE?</div>
//             <img className='three-arrow' src={threearrow} alt='logo' />
//             <button
//               onClick={() => {
//                 setIsGuest(false);
//               }}
//               className='submit-button'
//             >
//               Si, voy a participar
//             </button>
//             <button
//               onClick={() => {
//                 setIsGuest(true);
//               }}
//               className='submit-button filter-button'
//             >
//               No, solo quiero chismosear
//             </button>
//           </>
//         )}
//         {!existsError && isGuest !== null && !isGuest && (
//           <>
//             <img className='id_logo' src={idcard} alt='logo' />
//             <div className='sign-up-description'>
//               Por favor ingresa tu numero de cédula
//             </div>
//             <div className='field-container'>
//               <FormControl fullWidth>
//                 <TextField
//                   onChange={changeInput(setIdentificationCard)}
//                   value={identificationCard}
//                   type='number'
//                   id='identificationCard'
//                   label='Cédula'
//                 />
//               </FormControl>
//             </div>
//             <button
//               onClick={() => {
//                 sendAthlete();
//               }}
//               disabled={!identificationCard}
//               className='submit-button filter-button'
//             >
//               Continuar
//             </button>
//           </>
//         )}
//         {existsError && (
//           <>
//             <img className='id_logo' src={idcarderror} alt='logo' />
//             <div className='sign-up-description'>
//               Tu cédula no coincide con los inscritos,
//               <br /> Si tienes algún problema conectando tu STRAVA
//               <br />
//               contáctanos a{' '}
//               <a href='mailto:ceropresion@tncgames.co'>
//                 ceropresion@tncgames.co
//               </a>
//             </div>
//             <div className='field-container'>
//               <FormControl fullWidth>
//                 <TextField
//                   onChange={changeInput(setIdentificationCard)}
//                   value={identificationCard}
//                   id='identificationCard'
//                   type='number'
//                   label='Cédula'
//                 />
//               </FormControl>
//             </div>
//             <button
//               onClick={() => {
//                 sendAthlete();
//               }}
//               disabled={!identificationCard}
//               className='submit-button'
//             >
//               Volver a intentar
//             </button>
//             {/* <button
//               onClick={() => {
//                 openIG();
//               }}
//               className='submit-button filter-button'
//             >
//               Escribir a IG
//             </button> */}
//           </>
//         )}
//         {!existsError && isGuest !== null && isGuest && (
//           <>
//             <img className='id_logo' src={emailicon} alt='logo' />
//             <div className='sign-up-description'>
//               Compartenos tu correo electronico
//             </div>
//             <div className='field-container'>
//               <FormControl fullWidth>
//                 <TextField
//                   onChange={changeInput(setEmail)}
//                   value={email}
//                   id='email'
//                   label='Email'
//                   error={!validEmail}
//                   helperText={validEmail ? '' : 'Formato de email incorrecto'}
//                 />
//               </FormControl>
//             </div>
//             <button
//               onClick={() => {
//                 sendGuest();
//               }}
//               disabled={!(!!email && !!validEmail)}
//               className='submit-button filter-button'
//             >
//               Continuar
//             </button>
//           </>
//         )}
//       </div>
//       {/* <Footer /> */}
//     </div>
//   );
// };

// export default SignUp;
