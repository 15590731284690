import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { titleCase } from '../../utils/utils';
import calendarIcon from '../../assets/images/calendar.svg';
import close from '../../assets/images/close.svg';

import './collectKitModal.scss';

const CollectKitModal = ({ closeModal }) => {
  const [currentAthlete, setCurrentAthlete] = useState({});

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    if (athlete && athlete.athlete) {
      setCurrentAthlete(athlete.athlete);
    }
  }, []);

  return (
    <div className='collect-kit-modal'>
      <div className='collect-kit-modal_card'>
        <div className='collect-kit-modal_card-body'>
          <div
            className='close-button'
            onClick={() => {
              closeModal();
            }}
          >
            <img src={close} alt='close' className='close-icon' />
          </div>
          <img
            src={calendarIcon}
            alt='calendar-icon'
            className='calendar-icon'
          />
          <div className='yellow-container'></div>
          <div className='login-welcome'>
            <div className='welcome-title'>Recoge tu KIT</div>
            <div className='black-container'></div>
            <div className='white-container'></div>
            <div className='welcome-description white'>
              El 18 - 23 de
              <br />
              Septiembre en:
            </div>
            <div className='welcome-description'>
              {currentAthlete &&
                currentAthlete.collectKit &&
                titleCase(currentAthlete.collectKit)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CollectKitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CollectKitModal;
