import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loading from '../../components/loading';
import './photos.scss';
import Header from '../Header';
import Footer from '../../components/Footer';
import { Row, Col } from 'react-flexbox-grid';
import Nav from '../Nav';
import Tabs from '../Tabs';
import config from '../../config';
import handleResponse from '../../utils/handleResponse';
import fbicon from '../../assets/images/fbicon.svg';
import igicon from '../../assets/images/igicon.svg';
import twicon from '../../assets/images/twicon.svg';
import nextarrow from '../../assets/images/nextarrow.svg';
import prevarrow from '../../assets/images/prevarrow.svg';
import downloadicon from '../../assets/images/downloadicon.svg';
import AWS from 'aws-sdk';

const TABS = ['Resultados', 'Fotos'];

const TAB_RESULTS = 0;
const TAB_PHOTOS = 1;

const s3 = new AWS.S3({
  region: config.TNC_AWS_REGION,
  accessKeyId: config.TNC_AWS_KEY_ID,
  secretAccessKey: config.TNC_AWS_ACCESS_KEY,
});

const Photos = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [photos, setPhotos] = useState([]);

  const download = async () => {
    const fileUrl = photos[photoIndex].Key;
    window.open(`${config.awsS3Url}${fileUrl}`, '_blank').focus();
    // const params = {
    //   Bucket: config.TNC_AWS_BUCKET,
    //   Key: fileUrl,
    // };

    // const fileArray = fileUrl.split('/');
    // const fileName = fileArray[fileArray.length - 1];
    // try {
    //   const response = await fetch(`${config.awsS3Url}${fileUrl}`);
    //   const blob = await response.blob();
    //   let link = document.createElement('a');
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = fileName;
    //   link.click();
    // } catch (error) {
    //   console.log('error', error);
    //   window.open(`${config.awsS3Url}${fileUrl}`, '_blank').focus();
    // }

    // s3.getObject(params, (err, data) => {
    //   if (err) {
    //     console.log(err, err.stack);
    //     window.open(`${config.awsS3Url}${fileUrl}`, '_blank').focus();
    //   } else {
    //     let blob = new Blob([data.Body], { type: data.ContentType });
    //     let link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = fileName;
    //     link.click();
    //   }
    // });
  };

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    getSegmets();
    if (athlete.athlete.isGuest) {
      setSelectedSegment('6896363');
    } else {
      // setShowCollectKitModal(true);
      setSelectedSegment(athlete.athlete.segmentStravaId);
    }
  }, []);

  useEffect(() => {
    if (selectedSegment) {
      getSegmetsPhotos();
    }
  }, [selectedSegment]);

  const goPrev = () => {
    setPhotoIndex(photoIndex - 1);
  };
  const goNext = () => {
    setPhotoIndex(photoIndex + 1);
  };

  const getSegmets = async () => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(
      `${config.apiUrl}/api/segments?pagination[limit]=100`,
      requestOptions
    );
    setShowLoading(false);
    try {
      const segments = await handleResponse(response);
      setSegments(segments.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getSegmetsPhotos = () => {
    const params = {
      Bucket: config.TNC_AWS_BUCKET,
      Delimiter: '/',
      Prefix: `photos/${selectedSegment}/`,
    };
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log('err', err);
      } else {
        const files = data.Contents;
        files.shift();
        setPhotos(files);
      }
    });
  };

  const onTabChange = (tab) => {
    switch (tab) {
      case TAB_RESULTS: {
        history.push('/ranking');
        break;
      }
      case TAB_PHOTOS: {
        break;
      }
      default: {
      }
    }
  };

  return (
    <div className='photos'>
      {showLoading && <Loading />}
      <div className='fixed-header'>
        <Header />
      </div>
      <Col xs={12} className='sub-header'>
        <Row>
          <Col xs={12} className='tabs-container'>
            <Tabs tabs={TABS} selectedTab={1} onChange={onTabChange} />
          </Col>
          <Col xs={12}>
            <Nav
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
              segments={segments.filter(
                (segment) =>
                  segment.stravaId === '22870576' ||
                  segment.stravaId === '806815' ||
                  segment.stravaId === '15952988' ||
                  segment.stravaId === '8979075' ||
                  segment.stravaId === '6611358' ||
                  segment.stravaId === '6896363'
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} className='body'>
        <Row>
          <Col xs={12} md={3} className='hide-mobile'>
            <div className='title'>
              REVIVE LOS
              <br /> <span>MEJORES</span>
              <br />
              <span>MOMENTOS</span>
              <br /> DEL COLOMBIA
              <br /> CHALLENGE
            </div>
            <div className='separattor' />
            <div className='sub-title'>
              Fue un orgullo haber contado
              <br /> contigo, por eso te invitamos
              <br /> a que compartas tus mejores
              <br /> momentos.
            </div>
            <div className='contact'>
              <a
                href='https://www.instagram.com/thenarinochallenge/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={igicon} alt='ig-icon' className='ig-icon' />
              </a>
            </div>
          </Col>
          <Col xs={12} md={3} className='hide-desktop'>
            <div className='title'>
              REVIVE LOS <span>MEJORES MOMENTOS</span>
              <br />
              DEL COLOMBIA CHALLENGE
            </div>
            <div className='separattor' />
          </Col>
          <Col xs={12} md={9}>
            <Row className='carousel-margin'>
              <div className='floating-arrows hide-mobile'>
                <div className='arrows-container'>
                  <button
                    className='arrow-item left-arrow'
                    disabled={photoIndex < 1}
                    onClick={goPrev}
                  >
                    <img
                      src={prevarrow}
                      alt='prev arrow'
                      className='prev-arrow'
                    />
                  </button>
                  <div className='arrow-separator' />
                  <button className='arrow-item rigth-arrow' onClick={goNext}>
                    <img
                      src={nextarrow}
                      alt='next arrow'
                      className='next-arrow'
                    />
                  </button>
                </div>
              </div>
              <Col className='carousel-container'>
                <div className='floating-arrow hide-desktop'>
                  <div className='arrow-container'>
                    <button
                      className='arrow-item left-arrow'
                      disabled={photoIndex < 1}
                      onClick={goPrev}
                    >
                      <img
                        src={prevarrow}
                        alt='prev arrow'
                        className='prev-arrow'
                      />
                    </button>
                  </div>
                </div>
                <div className='floating-arrow-right hide-desktop'>
                  <div className='arrow-container'>
                    <button className='arrow-item left-arrow' onClick={goNext}>
                      <img
                        src={nextarrow}
                        alt='next arrow'
                        className='next-arrow'
                      />
                    </button>
                  </div>
                </div>

                <button className='download-icon' onClick={download}>
                  <img src={downloadicon} alt='download-icon' />
                  Descargar
                </button>
                <div className='carousel-size'>
                  <Carousel
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    axis='horizontal'
                    autoPlay={false}
                    centerMode
                    centerSlidePercentage={100}
                    thumbWidth={100}
                    dynamicHeight
                    selectedItem={photoIndex}
                    onChange={setPhotoIndex}
                  >
                    {photos.map((photo, index) => {
                      return (
                        <div key={photo.Key}>
                          <img
                            crossorigin
                            id={`${selectedSegment}-${index}`}
                            src={`${config.awsS3Url}${photo.Key}`}
                            alt={`event ${index}`}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={3} className='hide-desktop'>
            <div className='sub-title'>
              Fue un orgullo haber contado contigo, por eso te <br />
              invitamos a que compartas tus mejores momentos.
            </div>
            <div className='contact'>
              <a
                href='https://www.instagram.com/thenarinochallenge/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={igicon} alt='ig-icon' className='ig-icon' />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
      <Footer home />
    </div>
  );
};

export default Photos;
