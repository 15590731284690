import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingIndividualItem from '../TableRankingIndividualItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableRankingIndividual = ({
  setShowLoading,
  setShowSecondLoading,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedGenderFilter,
  changeIndividual,
  eighteenThirtyCategoryFilter,
  thirtyOneFortyFourCategoryFilter,
  fortyFiveFiftyNineCategoryFilter,
  sixtyPlusCategoryFilter,
  turboCategoryFilter,
  selectedSegment,
  filterText,
  isBike,
}) => {
  const [rakings, setRakings] = useState([]);
  const [rakingsFavorite, setRakingsFavorite] = useState([]);

  const getRanking = async (isBike) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['sportType'] = isBike === 0 ? 'bike' : 'run';

    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (
      !(
        eighteenThirtyCategoryFilter &&
        thirtyOneFortyFourCategoryFilter &&
        fortyFiveFiftyNineCategoryFilter &&
        sixtyPlusCategoryFilter &&
        turboCategoryFilter
      )
    ) {
      if (eighteenThirtyCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['eighteenThirty'] = eighteenThirtyCategoryFilter;
      }
      if (thirtyOneFortyFourCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['thirtyOneFortyFour'] =
          thirtyOneFortyFourCategoryFilter;
      }
      if (fortyFiveFiftyNineCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['fortyFiveFiftyNine'] =
          fortyFiveFiftyNineCategoryFilter;
      }
      if (sixtyPlusCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['sixtyPlus'] = sixtyPlusCategoryFilter;
      }
      if (turboCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['turbo'] = turboCategoryFilter;
      }
    }

    if (filterText) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['search'] = filterText;
    }
    if (selectedSegment) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['segmentStravaId'] = selectedSegment;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(`${config.apiUrl}/api/athletes/ranking`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newRankings = await handleResponse(response);
      setRakings(newRankings.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getRankingFavorite = async (user) => {
    // setShowSecondLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        individualRankingFavorite: true,
      },
    };

    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (filterText) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['search'] = filterText;
    }

    if (
      !(
        eighteenThirtyCategoryFilter &&
        thirtyOneFortyFourCategoryFilter &&
        fortyFiveFiftyNineCategoryFilter &&
        sixtyPlusCategoryFilter &&
        turboCategoryFilter
      )
    ) {
      if (eighteenThirtyCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['eighteenThirty'] = eighteenThirtyCategoryFilter;
      }
      if (thirtyOneFortyFourCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['thirtyOneFortyFour'] =
          thirtyOneFortyFourCategoryFilter;
      }
      if (fortyFiveFiftyNineCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['fortyFiveFiftyNine'] =
          fortyFiveFiftyNineCategoryFilter;
      }
      if (sixtyPlusCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['sixtyPlus'] = sixtyPlusCategoryFilter;
      }
      if (turboCategoryFilter) {
        if (!params['query']) {
          params['query'] = {};
        }
        params['query']['turbo'] = turboCategoryFilter;
      }
    }

    if (selectedSegment) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['segmentStravaId'] = selectedSegment;
    }

    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(`${config.apiUrl}/api/athletes/ranking`);

    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    // setShowSecondLoading(false);
    try {
      const newRakingsFavorite = await handleResponse(response);
      setRakingsFavorite(newRakingsFavorite.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (individualFavorites && selectedSegment) {
      getRankingFavorite();
    }
  }, [individualFavorites]);

  useEffect(() => {
    getRanking(isBike);
  }, [isBike]);

  useEffect(() => {
    if (changeIndividual && selectedSegment) {
      getRanking(isBike);
      getRankingFavorite();
    }
  }, [changeIndividual]);

  return (
    <Col xs={12}>
      <Row>
        {rakings.length > 0 &&
          rakingsFavorite.map((raking) => {
            return (
              <TableRankingIndividualItem
                userInfo={raking}
                key={`favorite${raking._id}`}
                darkMode
                firstRaking={rakings[0]}
                individualFavorites={individualFavorites}
                saveFavorite={saveFavorite}
                deleteFavorite={deleteFavorite}
                isBike={isBike}
              />
            );
          })}
        {rakings.length === 0 && <EmptyState />}
        {rakings.map((raking) => {
          return (
            <TableRankingIndividualItem
              userInfo={raking}
              key={`individual${raking._id}`}
              individualFavorites={individualFavorites}
              saveFavorite={saveFavorite}
              deleteFavorite={deleteFavorite}
              firstRaking={rakings[0]}
              isBike={isBike}
            />
          );
        })}
      </Row>
    </Col>
  );
};

TableRankingIndividual.propTypes = {
  isBike: PropTypes.number.isRequired,
  setShowLoading: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  individualFavorites: PropTypes.array.isRequired,
  saveFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  selectedGenderFilter: PropTypes.string,
  eighteenThirtyCategoryFilter: PropTypes.string,
  thirtyOneFortyFourCategoryFilter: PropTypes.string,
  fortyFiveFiftyNineCategoryFilter: PropTypes.string,
  sixtyPlusCategoryFilter: PropTypes.string,
  turboCategoryFilter: PropTypes.string,
  selectedSegment: PropTypes.string,
  filterText: PropTypes.string,
  changeIndividual: PropTypes.number,
};

TableRankingIndividual.defaultProps = {
  eighteenThirtyCategoryFilter: null,
  thirtyOneFortyFourCategoryFilter: null,
  fortyFiveFiftyNineCategoryFilter: null,
  sixtyPlusCategoryFilter: null,
  turboCategoryFilter: null,
  selectedGenderFilter: null,
  changeIndividual: null,
  selectedSegment: null,
  filterText: null,
};

export default TableRankingIndividual;
