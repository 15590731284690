import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import Footer from '../../components/Footer';
import { titleCase } from '../../utils/utils';

import './editAthlete.scss';
import Loading from '../../components/loading';
import config from '../../config';
import {
  handleResponse,
  handleStravaResponse,
  serializeQuery,
} from '../../utils/handleResponse';
import camera from '../../assets/images/camara.svg';
import threearrow from '../../assets/images/3arrow.svg';
import Header from '../Header';

const GENDERS = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
];

const SIZES = [
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: '3XL', label: '3XL' },
  { value: '4XL', label: '4XL' },
];

const BIKES = [
  'AGR',
  'ARGON',
  'ARGON18',
  'BASSO',
  'BIANCHI',
  'BLUE',
  'BMC',
  'BTWIN',
  'CANNONDALE',
  'CANYON',
  'CEEPO',
  'CERVELO',
  'COLNAGO',
  'CUBE',
  'DEROSA',
  'FACTOR',
  'FELT',
  'FOCUS',
  'FUJI',
  'GIANT',
  'GUERCIOTTI',
  'GW',
  'KESTREL',
  'KTM',
  'LAPIERRE',
  'LIV',
  'LOOK',
  'MERIDA',
  'ORBEA',
  'PINARELLO',
  'QUINTANAROO',
  'RALEIGH',
  'RIDLEY',
  'ROSSETTI',
  'SCOTT',
  'SPECIALIZED',
  'STL',
  'TREK',
  'URANIUM',
  'WILIER',
];

const EditAthlete = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [collectKit, setCollectKit] = useState('');
  const [gender, setGender] = useState('');
  const [sizeShirt, setSizeShirt] = useState('');
  const [bicycleBrand, setBicycleBrand] = useState('');
  const [initialSegmentStravaId, setInitialSegmentStravaId] = useState('');
  const [segmentStravaId, setSegmentStravaId] = useState('');
  const [currentAthlete, setCurrentAthlete] = useState();
  const [segments, setSegments] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [prDate, setPrDate] = useState(null);
  const [prTime, setPrTime] = useState(null);

  const today = moment();
  const maxSegmentDate = moment('2024-06-17', 'YYYY-MM-DD');
  const maxTshirtDate = moment('2024-06-01', 'YYYY-MM-DD');
  const disabledSegment = maxSegmentDate < today;

  const disabledTshirt = maxTshirtDate < today;

  useEffect(() => {
    if (currentAthlete) {
      getSegmets();
      setFullName(currentAthlete.fullName);
      setEmail(currentAthlete.email);
      setCollectKit(currentAthlete.collectKit);
      setGender(currentAthlete.gender);
      setSizeShirt(currentAthlete.sizeShirt);
      setSegmentStravaId(currentAthlete.segmentStravaId);
      setInitialSegmentStravaId(currentAthlete.segmentStravaId);
      setBicycleBrand(currentAthlete.bicycleBrand);
      getAccesToken();
    }
  }, [currentAthlete]);

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    if (athlete && athlete.athlete) {
      setCurrentAthlete(athlete.athlete);
    }
  }, []);

  useEffect(() => {
    if (segmentStravaId && accessToken) {
      getStravaSegmentEfforts(segmentStravaId);
    }
  }, [segmentStravaId]);

  const getAccesToken = async () => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: config.clientID,
      client_secret: config.clientSecret,
      refresh_token: currentAthlete.refreshToken,
      grant_type: 'refresh_token',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      const refreshToken = body.refresh_token;
      setAccessToken(body.access_token);
      updateAthlete({
        refreshToken,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEfforts = async (segmentId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts?segment_id=${segmentId}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthleteEfforts = await handleStravaResponse(response);
      // const stravaAthleteEffortFound = stravaAthleteEfforts.find(
      //   (stravaAthleteEffort) => `${stravaAthleteEffort.id}`.length === 11
      // );
      if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
        getStravaSegmentEffort(stravaAthleteEfforts[0].id);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEffort = async (effortId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts/${effortId.toString()}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);

    setShowLoading(false);
    try {
      const stravaAthleteEffort = await handleResponse(response);
      setPrDate(stravaAthleteEffort.athlete_segment_stats.pr_date);
      setPrTime(stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getSegmets = async () => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        sportType: currentAthlete.sportType || 'bike',
      },
    };

    const url = new URL(`${config.apiUrl}/api/segments`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);

    setShowLoading(false);
    try {
      const segments = await handleResponse(response);
      setSegments(segments.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  // use current access token to call all activities
  // const getStravaAthlete = async () => {
  //   setShowLoading(true);
  //   const url = new URL(`${config.stravaApiUrl}/athlete`);
  //   const headers = {
  //     Authorization: `Bearer ${authBearer}`,
  //   };
  //   const requestOptions = {
  //     method: 'GET',
  //     headers,
  //   };
  //   const response = await fetch(url, requestOptions);
  //   setShowLoading(false);
  //   try {
  //     const stravaAthlete = await handleResponse(response);
  //     setStravaUser(stravaAthlete);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const updateAthlete = async (bodyToken = null) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const url = new URL(`${config.apiUrl}/api/athletes/${athlete.athlete._id}`);
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const segment = segments.find(
      (segmentItem) => segmentItem.stravaId === segmentStravaId
    );
    const body = bodyToken || {
      sizeShirt,
      segment: segment.name,
      segmentCity: segment.cityName,
      segmentId: segment['_id'],
      segmentStravaId: segment.stravaId,
      bicycleBrand,
      collectKit,
    };

    if (initialSegmentStravaId !== segmentStravaId) {
      body['prDate'] = prDate
        ? moment(prDate, 'YYYY-MM-DD').toISOString()
        : null;
      body['prTime'] = prTime || 9999999;
    }

    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const updatedAthlete = await handleResponse(response);
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...updatedAthlete, isGuest: currentAthlete.isGuest },
          token: athlete.token,
        })
      );
      if (!bodyToken) {
        window.location.replace('/ranking');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const save = () => {
    updateAthlete();
  };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const logOut = () => {
    localStorage.removeItem('athleteLogged3');
    localStorage.removeItem('access');
    window.location.replace('/ranking');
  };

  return (
    <div className='edit-athlete'>
      {showLoading && <Loading />}
      <Header editAthlete />
      <div className='photo-section'>
        <div className='photo-background' />
        <div className='photo-container'>
          <div className='photo-area'>
            <img src={camera} alt='camera' className='upload-photo' />
            {currentAthlete && (
              <img
                src={currentAthlete.photoUrl}
                alt='user'
                className='user-photo'
              />
            )}
          </div>
        </div>
      </div>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12}>
            <div className='edit-title-container'>
              <div className='edit-title'>
                INFORMACIÓN
                <br /> PERSONAL
              </div>
              <img className='three-arrow' src={threearrow} alt='logo' />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setFullName)}
                value={fullName}
                id='fullName'
                disabled
                label='Nombre Completo'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setEmail)}
                value={email}
                disabled
                id='email'
                label='Email'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='gender-label'>Genero</InputLabel>
              <Select
                labelId='gender-label'
                id='gender'
                disabled
                onChange={changeInput(setGender)}
                value={gender}
              >
                {GENDERS.map((selectGender) => {
                  return (
                    <MenuItem
                      key={selectGender.value}
                      value={selectGender.value}
                    >
                      {selectGender.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='sizeShirt-label'>Talla Jersey</InputLabel>
              <Select
                labelId='sizeShirt-label'
                id='sizeShirt'
                onChange={changeInput(setSizeShirt)}
                disabled={disabledTshirt}
                value={sizeShirt}
              >
                {SIZES.map((selectSizeShirt) => {
                  return (
                    <MenuItem
                      key={selectSizeShirt.value}
                      value={selectSizeShirt.value}
                    >
                      {selectSizeShirt.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Cambios hasta 31 de Mayo</FormHelperText>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='segmentStravaId-label'>Segmento</InputLabel>
              <Select
                labelId='segmentStravaId-label'
                id='segmentStravaId'
                onChange={changeInput(setSegmentStravaId)}
                value={segmentStravaId}
                disabled={disabledSegment}
              >
                {segments.map((selectSegmentStravaId) => {
                  return (
                    <MenuItem
                      key={selectSegmentStravaId['_id']}
                      value={selectSegmentStravaId.stravaId}
                    >
                      {selectSegmentStravaId.cityName},{' '}
                      {titleCase(selectSegmentStravaId.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Cambios hasta 16 de Junio</FormHelperText>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='bicycleBrand-label'>
                Marca de bicicleta
              </InputLabel>
              <Select
                labelId='bicycleBrand-label'
                id='bicycleBrand'
                onChange={changeInput(setBicycleBrand)}
                value={bicycleBrand}
              >
                {BIKES.map((selectBike) => {
                  return (
                    <MenuItem key={selectBike} value={selectBike}>
                      {selectBike}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setCollectKit)}
                value={collectKit}
                id='collectKit'
                label='Dirección'
                helperText='Cambios hasta 16 de Junio'
                disabled={disabledSegment}
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} mdOffset={3}>
            <button
              onClick={save}
              className='submit-button filter-button'
              disabled={
                !(
                  !!fullName &&
                  !!email &&
                  !!gender &&
                  !!sizeShirt &&
                  !!collectKit
                )
              }
            >
              Guardar
            </button>
          </Col>
          <Col xs={12} md={6} mdOffset={3}>
            <button onClick={logOut} className='submit-button log-out'>
              Log Out
            </button>
          </Col>
        </Row>
      </Col>
      <Footer />
    </div>
  );
};

export default EditAthlete;
