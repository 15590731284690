import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './assets/robotofonts.css';
import './App.scss';
import PrivateRoute from './components/PrivateRoute';
import Home from './containers/Home';
import Photos from './containers/Photos';
import EditAthlete from './containers/EditAthlete';
import Login from './containers/Login';
import SignUp from './containers/SignUp';

const App = () => {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <PrivateRoute exact path='/ranking' component={Home} />
          <PrivateRoute exact path='/photos' component={Photos} />
          <PrivateRoute exact path='/edit' component={EditAthlete} />
          <Route path='/login' component={Login} />
          <Route path='/sign-up' component={SignUp} />
          <Redirect from='*' to='/ranking' />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
