import React from 'react';
import empty from '../../assets/images/empty.png';
import './emptyState.scss';

const EmptyState = () => {
  return (
    <div className='empty-state'>
      <img src={empty} alt='clear' className='empty-state_img' />
    </div>
  );
};

export default EmptyState;
