import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import stravaLogo from '../../assets/images/strava.svg';
import favoritewhite from '../../assets/images/favoritewhite.svg';
import favoriteyellow from '../../assets/images/favoriteyellow.svg';
import defaultFoto from '../../assets/images/foto.jpeg';
import eighteenThirtyCategoryIcon from '../../assets/images/categories/eighteenThirtyCategory.svg';
import thirtyOneFortyCategoryIcon from '../../assets/images/categories/thirtyOneFortyCategory.svg';
import fortyOneNinetyCategoryIcon from '../../assets/images/categories/fortyOneNinetyCategory.svg';
import specializedIcon from '../../assets/images/specialized.svg';
import bmcIcon from '../../assets/images/bmc.png';
import turboIcon from '../../assets/images/categories/turbo.svg';

import { Row, Col } from 'react-flexbox-grid';
import { titleCase } from '../../utils/utils';

export const EIGHTEEN_THIRTY = '18-30';
export const THIRTY_ONE_FORTY_FOUR = '31-44';
export const FORTY_FIVE_FIFTY_NINE = '45-59';
export const SIXTY_PLUS = '60+';

moment.locale('es');

const TableRankingIndividualItem = ({
  userInfo,
  firstRaking,
  darkMode,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedSegmentFilter,
  isBike,
}) => {
  const openStravaProfile = (stravaId) => {
    window.open(`https://www.strava.com/athletes/${stravaId}`, '_newtab');
  };

  const addButtonText = () => {
    let favoriteIcon = favoritewhite;
    let saveFunction = saveFavorite;
    let favoriteId = userInfo.athlete._id;
    if (individualFavorites) {
      individualFavorites.forEach((individualFavorite) => {
        if (individualFavorite.favorite === userInfo.athlete._id) {
          favoriteIcon = favoriteyellow;
          favoriteId = individualFavorite._id;
          saveFunction = deleteFavorite;
        }
      });
    }
    return (
      <div
        onClick={() => {
          saveFunction(favoriteId);
        }}
        className='add-button'
      >
        <img src={favoriteIcon} className='favorite-icon' alt='favoriteIcon' />
      </div>
    );
  };

  const showTime = (timeDate) => {
    if (timeDate) {
      return (
        <div>
          {new Date(timeDate * 1000).toISOString().substr(11, 8)}
          {firstRaking._id !== userInfo._id && (
            <div className='table-ranking-item_user-info-team'>
              {firstRaking._id !== userInfo._id && <span>{'+ '}</span>}
              {firstRaking._id === userInfo._id
                ? `${new Date(timeDate * 1000).toISOString().substr(11, 8)}`
                : `${new Date(
                    Math.abs(
                      timeDate -
                        firstRaking[
                          selectedSegmentFilter
                            ? selectedSegmentFilter
                            : 'elapsedTime'
                        ]
                    ) * 1000
                  )
                    .toISOString()
                    .substr(11, 8)}`}
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        <Tooltip
          enterTouchDelay={100}
          title='No hay tiempo o no es Strava Premium'
          placement='top'
        >
          <span>⚠️</span>
        </Tooltip>
      </div>
    );
  };

  const getCategoryImg = () => {
    switch (userInfo.athlete.category) {
      case EIGHTEEN_THIRTY:
        return <div className='category-label'>18-30 años</div>;
      case THIRTY_ONE_FORTY_FOUR:
        return <div className='category-label gray'>31-44 años</div>;
      case FORTY_FIVE_FIFTY_NINE:
        return <div className='category-label blue'>45-59 años</div>;
      default:
        return <div className='category-label black'>60+ años</div>;
    }
  };

  return (
    <Col xs={12} className={`table-ranking-item${darkMode ? ' darkMode' : ''}`}>
      <Row className='table-ranking-item_column-container'>
        <Col xs={12}>
          <Row className='table-ranking-item_column-container_main individual'>
            <Col xs={2} className='table-ranking-item_column'>
              {addButtonText()}
              <div className='position-text'>{userInfo.rank + 1}</div>
            </Col>
            <Col
              xs={6}
              className='table-ranking-item_column'
              onClick={() => {
                if (userInfo.athlete.stravaId) {
                  openStravaProfile(userInfo.athlete.stravaId);
                }
              }}
            >
              <div className='table-ranking-item_user-img-container'>
                <img
                  src={userInfo.athlete.photoUrl || defaultFoto}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultFoto;
                  }}
                  alt='user'
                  className='table-ranking-item_user-img'
                />
                {userInfo.athlete.stravaId && (
                  <img
                    src={stravaLogo}
                    alt='strava logo'
                    className='strava-logo individual'
                  />
                )}
              </div>
              <div className='table-ranking-item_user-info-container'>
                <div className='table-ranking-item_user-info-name'>
                  {userInfo.athlete.fullName}
                </div>
                {!isBike && (
                  <div className='table-ranking-item_user-info-city'>
                    {userInfo.athlete.turbo ? (
                      <img
                        src={turboIcon}
                        className='category-img-item'
                        alt='turboIcon'
                      />
                    ) : (
                      getCategoryImg()
                    )}
                  </div>
                )}
              </div>
              <div className='table-ranking-item_user-img-container specialized'>
                {userInfo.athlete.bicycleBrand === 'BMC' && (
                  <img src={bmcIcon} alt='user' className='specialized-icon' />
                )}
              </div>
            </Col>
            {/* <Col xs={2} className='table-ranking-item_column'>
              <div className='table-ranking-item_category'>
                <div>
                  {userInfo.elapsedTime ? (
                    new Date(userInfo.elapsedTime * 1000)
                      .toISOString()
                      .substr(11, 8)
                  ) : (
                    <div>
                      <Tooltip
                        enterTouchDelay={100}
                        title='No hay tiempo o no es Strava Premium'
                        placement='top'
                      >
                        <span>⚠️</span>
                      </Tooltip>
                    </div>
                  )}
                  {userInfo.athlete && userInfo.athlete.prDate && (
                    <div className='table-ranking-item_user-info-team'>
                      {titleCase(
                        moment(userInfo.athlete.prDate).format('MMMM YYYY')
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col> */}
            <Col xs={4} className='table-ranking-item_column'>
              <div className='table-ranking-item_category'>
                <div>
                  {/* {userInfo.athlete.prTime !== 9999999
                    ? new Date(userInfo.athlete.prTime * 1000)
                        .toISOString()
                        .substr(11, 8)
                    : '-'}
                  {userInfo.athlete &&
                    userInfo.athlete.prTime !== 9999999 &&
                    userInfo.athlete.prDate && (
                      <div className='table-ranking-item_user-info-team'>
                        {titleCase(
                          moment(userInfo.athlete.prDate).format('MMMM YYYY')
                        )}
                      </div>
                    )} */}
                  {userInfo.elapsedTime
                    ? new Date(userInfo.elapsedTime * 1000)
                        .toISOString()
                        .substr(11, 8)
                    : '-'}
                  {/* {userInfo.athlete.elapsedTime && (
                    <div className='table-ranking-item_user-info-team'>
                      🇨🇴 20 de julio
                    </div>
                  )} */}
                  <div className='table-ranking-item_user-info-team'>
                    🇨🇴 20 de julio
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

TableRankingIndividualItem.propTypes = {
  saveFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  firstRaking: PropTypes.object.isRequired,
  darkMode: PropTypes.bool,
  individualFavorites: PropTypes.array.isRequired,
  selectedSegmentFilter: PropTypes.string,
  isBike: PropTypes.number.isRequired,
};

TableRankingIndividualItem.defaultProps = {
  darkMode: false,
  selectedSegmentFilter: null,
};

export default TableRankingIndividualItem;
