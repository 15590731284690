import React from 'react';
import logo from '../../assets/images/logo.png';
import './footer.scss';

const Footer = ({ home }) => {
  return (
    <footer className={`footer ${home ? 'home' : ''}`}>
      Un evento
      <img src={logo} alt='clear' className='footer-logo' />
    </footer>
  );
};

export default Footer;
