import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';

import Loading from '../../components/loading';
import './home.scss';
import Header from '../Header';
import Footer from '../../components/Footer';
import { Row, Col } from 'react-flexbox-grid';
import Nav from '../Nav';
import Tabs from '../Tabs';
import TableRankingTitle from '../TableRankingTitle';
import TableRankingIndividual from '../TableRankingIndividual';
import FilterModal from '../FilterModal';
import CollectKitModal from '../CollectKitModal';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
import banner from '../../assets/images/banners-mobile.png';
import bannerfull from '../../assets/images/banners-web.png';
import wpicon from '../../assets/images/wpicon.png';

const TABS = ['BIKE', 'RUN'];

const TAB_BIKE = 0;
const TAB_RUN = 1;

const Home = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showCollectKitModal, setShowCollectKitModal] = useState(false);
  const [showSecondLoading, setShowSecondLoading] = useState(false);
  const [showIndividualFavoriteLoading, setShowIndividualFavoriteLoading] =
    useState(false);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [individualFavorites, setIndividualFavorites] = useState([]);
  const [selectedGenderFilter, setSelectedGenderFilter] = useState(null);

  const [changeIndividual, setChangeIndividual] = useState(null);
  const [eighteenThirtyCategory, setEighteenThirtyCategory] = useState(true);
  const [thirtyOneFortyFourCategory, setThirtyOneFortyFourCategory] =
    useState(true);
  const [fortyFiveFiftyNineCategory, setFortyFiveFiftyNineCategory] =
    useState(true);
  const [sixtyPlusCategory, setSixtyPlusCategory] = useState(true);
  const [turboCategory, setTurboCategory] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);

  const resetFilters = () => {
    setSelectedGenderFilter(null);
    setEighteenThirtyCategory(true);
    setThirtyOneFortyFourCategory(true);
    setFortyFiveFiftyNineCategory(true);
    setSixtyPlusCategory(true);
    setTurboCategory(true);
    if (filterText) {
      setFilterText('');
    } else {
      setChangeIndividual(new Date().getTime());
    }
  };

  const getLastUpdate = async () => {
    setShowLoading(true);
    const headers = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(
      `${config.apiUrl}/auth/last-activity`,
      requestOptions
    );
    setShowLoading(false);
    try {
      const lastUpdates = await handleResponse(response);
      if (lastUpdates && lastUpdates.length > 0) {
        setLastUpdate(lastUpdates[0]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const openIG = () => {
    const apiWs =
      window.innerWidth < 1024
        ? 'https://api.whatsapp.com/send?phone=573016428419&text=Hola+TNC%2c+estoy+inscrito+al+COLOMBIA+CHALLENGE+y+estoy+interesado+en+comprar+una+BMC+con+10%25+OFF%e2%80%a6'
        : 'https://web.whatsapp.com/send?phone=573016428419&text=Hola+TNC%2c+estoy+inscrito+al+COLOMBIA+CHALLENGE+y+estoy+interesado+en+comprar+una+BMC+con+10%25+OFF%e2%80%a6';
    window.open(apiWs, '_blank');
  };

  const getAthlete = async (stravaId) => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/strava-id`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      stravaId,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      return body;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaAthlete = async (athleteLogged) => {
    const athlete = await getAthlete(athleteLogged.athlete.stravaId);
    if (athlete && athlete.token) {
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...athlete.athlete, isGuest: false },
          token: athlete.token,
        })
      );
      // if (!athlete.athlete.isGuest) {
      //   setShowCollectKitModal(true);
      // }
      // history.replace('/');
    }
  };

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    getStravaAthlete(athlete);
    getSegmets();
    getIndividualFavorites();
    getLastUpdate();
    if (athlete.athlete.isGuest) {
      setSelectedSegment('6896363');
    } else {
      setSelectedTab(athlete.athlete.sportType === 'run' ? 1 : 0);
      setSelectedSegment(athlete.athlete.segmentStravaId);
    }
  }, []);

  const getRanking = () => {
    setChangeIndividual(new Date().getTime());
  };

  const debouncedGetRanking = useCallback(debounce(getRanking, 500), []);

  useEffect(() => {
    if (selectedSegment) {
      setChangeIndividual(new Date().getTime());
    }
  }, [selectedSegment]);

  useEffect(() => {
    debouncedGetRanking();
  }, [filterText]);

  useEffect(() => {
    if (selectedTab !== null) {
      resetFilters();
      const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
      if (athlete.athlete.isGuest) {
        if (selectedTab === 0) {
          setSelectedSegment('6896363');
        } else {
          setSelectedSegment('5783246');
        }
      } else {
        console.log('selectedTab', selectedTab);
        if (selectedTab === 0) {
          if (athlete.athlete.sportType === 'run') {
            setSelectedSegment('6896363');
          } else {
            setSelectedSegment(athlete.athlete.segmentStravaId);
          }
        }
        if (selectedTab === 1) {
          if (athlete.athlete.sportType !== 'run') {
            setSelectedSegment('5783246');
          } else {
            setSelectedSegment(athlete.athlete.segmentStravaId);
          }
        }
      }
      getSegmets();
    }
  }, [selectedTab]);

  const getSegmets = async () => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    console.log('selectedTab', selectedTab);
    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        sportType: selectedTab === 0 ? 'bike' : 'run',
      },
    };

    const url = new URL(`${config.apiUrl}/api/segments`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const segments = await handleResponse(response);
      setSegments(segments.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getIndividualFavorites = async () => {
    // setShowIndividualFavoriteLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/individual-rankings/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/individual-rankings/individual-athletes-ranking-favorites`
      );
    }

    const response = await fetch(url, requestOptions);
    // setShowIndividualFavoriteLoading(false);
    try {
      const newIndividualFavorites = await handleResponse(response);
      setIndividualFavorites(newIndividualFavorites);
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        favorite,
      }),
    };
    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/individual-rankings/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/individual-rankings/individual-athletes-ranking-favorites`
      );
    }
    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'DELETE',
      headers,
    };
    const url = new URL(
      `${config.apiUrl}/api/individual-rankings/individual-ranking-favorites/${favorite}`
    );

    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const onTabChange = (tab) => {
    setSelectedTab(tab);
    console.log('tab', tab);
    // switch (tab) {
    //   case TAB_BIKE: {
    //     break;
    //   }
    //   case TAB_RUN: {
    //     history.push('/');
    //     break;
    //   }
    //   default: {
    //   }
    // }
  };

  const renderTable = () => {
    return (
      <TableRankingIndividual
        isBike={selectedTab}
        setShowLoading={setShowLoading}
        setShowSecondLoading={setShowSecondLoading}
        individualFavorites={individualFavorites}
        saveFavorite={saveFavorite}
        deleteFavorite={deleteFavorite}
        eighteenThirtyCategoryFilter={eighteenThirtyCategory}
        thirtyOneFortyFourCategoryFilter={thirtyOneFortyFourCategory}
        fortyFiveFiftyNineCategoryFilter={fortyFiveFiftyNineCategory}
        sixtyPlusCategoryFilter={sixtyPlusCategory}
        turboCategoryFilter={turboCategory}
        selectedGenderFilter={selectedGenderFilter}
        changeIndividual={changeIndividual}
        selectedSegment={selectedSegment}
        filterText={filterText}
      />
    );
  };

  return (
    <div className='home'>
      {(showLoading || showIndividualFavoriteLoading || showSecondLoading) && (
        <Loading />
      )}
      {showFilterModal && (
        <FilterModal
          closeModal={() => {
            setShowFilterModal(false);
          }}
          selectedGenderFilter={selectedGenderFilter}
          setSelectedGenderFilter={setSelectedGenderFilter}
          eighteenThirtyCategoryFilter={eighteenThirtyCategory}
          setEighteenThirtyCategoryFilter={setEighteenThirtyCategory}
          thirtyOneFortyFourCategoryFilter={thirtyOneFortyFourCategory}
          setThirtyOneFortyFourCategoryFilter={setThirtyOneFortyFourCategory}
          fortyFiveFiftyNineCategoryFilter={fortyFiveFiftyNineCategory}
          setFortyFiveFiftyNineCategoryFilter={setFortyFiveFiftyNineCategory}
          sixtyPlusCategoryFilter={sixtyPlusCategory}
          setSixtyPlusCategoryFilter={setSixtyPlusCategory}
          turboCategoryFilter={turboCategory}
          setTurboCategoryFilter={setTurboCategory}
          setChangeIndividual={setChangeIndividual}
          isBike={selectedTab}
        />
      )}
      {showCollectKitModal && (
        <CollectKitModal
          closeModal={() => {
            setShowCollectKitModal(false);
          }}
        />
      )}
      <Header
        resetFilters={resetFilters}
        showReset={
          !!selectedGenderFilter ||
          !!filterText ||
          !eighteenThirtyCategory ||
          !thirtyOneFortyFourCategory ||
          !fortyFiveFiftyNineCategory ||
          !sixtyPlusCategory ||
          !turboCategory
        }
      />
      {lastUpdate && (
        <div className='last-update'>
          Última actualización{' '}
          {moment(lastUpdate.lastUpdate).format('DD/MM/YYYY HH:mm')}
        </div>
      )}

      <img src={banner} alt='banner' className='hide-desktop banner' />
      <img src={bannerfull} alt='banner' className='hide-mobile banner' />
      <div className='fixed-header'>
        <Col xs={12} className='sub-header'>
          <Row>
            <Col xs={12} className='tabs-container'>
              <Tabs
                tabs={TABS}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                onChange={onTabChange}
              />
            </Col>
            <Col xs={12}>
              <Nav
                selectedSegment={selectedSegment}
                setSelectedSegment={setSelectedSegment}
                filterText={filterText}
                setFilterText={setFilterText}
                segments={segments}
                openFilter={() => {
                  setShowFilterModal(true);
                }}
              />
            </Col>
          </Row>
        </Col>
        <TableRankingTitle />
      </div>
      {renderTable()}
      <div className='wp-fixed'>
        <button
          onClick={() => {
            openIG();
          }}
        >
          <img src={wpicon} alt='wp icon' />
          <p>
            Cotizar una <b>BMC</b> con 10% OFF
          </p>
        </button>
      </div>
      <Footer home />
    </div>
  );
};

export default Home;
