import React, { useState } from 'react';
import './tabs.scss';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className='tabs'>
      {tabs.map((tab, index) => {
        return (
          <div
            key={tab}
            className={`tab${index === selectedTab ? ' selected' : ''}`}
            onClick={() => {
              setSelectedTab(index);
            }}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default Tabs;
