import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import filterImg from '../../assets/images/filtros.svg';
import close from '../../assets/images/close.svg';
import eighteenThirtyCategoryIcon from '../../assets/images/categories/eighteenThirtyCategory.svg';
import thirtyOneFortyCategoryIcon from '../../assets/images/categories/thirtyOneFortyCategory.svg';
import fortyOneNinetyCategoryIcon from '../../assets/images/categories/fortyOneNinetyCategory.svg';
import turboIcon from '../../assets/images/categories/turbo.svg';

import './filterModal.scss';

const FilterModal = ({
  closeModal,
  selectedGenderFilter,
  setSelectedGenderFilter,
  eighteenThirtyCategoryFilter,
  setEighteenThirtyCategoryFilter,
  thirtyOneFortyFourCategoryFilter,
  setThirtyOneFortyFourCategoryFilter,
  fortyFiveFiftyNineCategoryFilter,
  setFortyFiveFiftyNineCategoryFilter,
  sixtyPlusCategoryFilter,
  setSixtyPlusCategoryFilter,
  turboCategoryFilter,
  setTurboCategoryFilter,
  setChangeIndividual,
  isBike,
}) => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [eighteenThirtyCategory, setEighteenThirtyCategory] = useState(false);
  const [thirtyOneFortyFourCategory, setThirtyOneFortyFourCategory] =
    useState(false);
  const [fortyFiveFiftyNineCategory, setFortyFiveFiftyNineCategory] =
    useState(false);
  const [sixtyPlusCategory, setSixtyPlusCategory] = useState(false);
  const [turboCategory, setTurboCategory] = useState(false);

  useEffect(() => {
    setEighteenThirtyCategory(eighteenThirtyCategoryFilter);
    setThirtyOneFortyFourCategory(thirtyOneFortyFourCategoryFilter);
    setFortyFiveFiftyNineCategory(fortyFiveFiftyNineCategoryFilter);
    setSixtyPlusCategory(sixtyPlusCategoryFilter);
    setTurboCategory(turboCategoryFilter);
    setSelectedGender(selectedGenderFilter);
  }, []);

  const filter = () => {
    setSelectedGenderFilter(selectedGender);
    setEighteenThirtyCategoryFilter(eighteenThirtyCategory);
    setThirtyOneFortyFourCategoryFilter(thirtyOneFortyFourCategory);
    setFortyFiveFiftyNineCategoryFilter(fortyFiveFiftyNineCategory);
    setSixtyPlusCategoryFilter(sixtyPlusCategory);
    setTurboCategoryFilter(turboCategory);
    closeModal();
    setChangeIndividual(new Date().getTime());
    window.scrollTo(0, 0);
  };

  return (
    <div className='filter-modal'>
      <div className='filter-modal_card'>
        <div className='filter-modal_card-header'>
          <div className='filter-modal_card-header-left'>
            <img
              src={filterImg}
              alt='filter'
              className='filter-modal_card-header_filter'
            />
            <div className='filter-modal_card-header_title'>FILTROS</div>
            <div
              className='close-button'
              onClick={() => {
                closeModal();
              }}
            >
              <img src={close} alt='close' className='close-icon' />
            </div>
          </div>
        </div>
        <div className='filter-modal_card-body'>
          <div className='filter-modal_card-section'>
            <div className='filter-modal_card-section-title'>GENERO</div>
            <div className='tags-container'>
              <div
                onClick={() => {
                  setSelectedGender(null);
                }}
                className={`tag${!selectedGender ? ' selected' : ''}`}
              >
                Todos
              </div>
              <div
                onClick={() => {
                  setSelectedGender('male');
                }}
                className={`tag${selectedGender === 'male' ? ' selected' : ''}`}
              >
                Hombres
              </div>
              <div
                onClick={() => {
                  setSelectedGender('female');
                }}
                className={`tag${
                  selectedGender === 'female' ? ' selected' : ''
                }`}
              >
                Mujeres
              </div>
            </div>
          </div>
          {!!!isBike && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>CATEGORIA</div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={eighteenThirtyCategory}
                      onChange={(event) => {
                        setEighteenThirtyCategory(event.target.checked);
                      }}
                      name='eighteenThirtyCategory'
                    />
                  }
                  label={<div className='category-label'>18-30 años</div>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thirtyOneFortyFourCategory}
                      onChange={(event) => {
                        setThirtyOneFortyFourCategory(event.target.checked);
                      }}
                      name='jason'
                    />
                  }
                  label={<div className='category-label gray'>31-44 años</div>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fortyFiveFiftyNineCategory}
                      onChange={(event) => {
                        console.log();
                        setFortyFiveFiftyNineCategory(event.target.checked);
                      }}
                      name='fortyFiveFiftyNineCategory'
                    />
                  }
                  label={<div className='category-label blue'>45-59 años</div>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sixtyPlusCategory}
                      onChange={(event) => {
                        console.log();
                        setSixtyPlusCategory(event.target.checked);
                      }}
                      name='sixtyPlusCategory'
                    />
                  }
                  label={<div className='category-label black'>60+ años</div>}
                />
                {/* <FormControlLabel
              control={
                <Checkbox
                  checked={turboCategory}
                  onChange={(event) => {
                    console.log();
                    setTurboCategory(event.target.checked);
                  }}
                  name='turboCategory'
                />
              }
              label={
                <img
                  src={turboIcon}
                  className='category-img'
                  alt='turboIcon'
                />
              }
            /> */}
              </FormGroup>
            </div>
          )}

          <button onClick={filter} className='filter-button'>
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

FilterModal.propTypes = {
  setSelectedGenderFilter: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedGenderFilter: PropTypes.string,
  eighteenThirtyCategoryFilter: PropTypes.string,
  setEighteenThirtyCategoryFilter: PropTypes.func.isRequired,
  thirtyOneFortyFourCategoryFilter: PropTypes.string,
  setThirtyOneFortyFourCategoryFilter: PropTypes.func.isRequired,
  fortyFiveFiftyNineCategoryFilter: PropTypes.string,
  setFortyFiveFiftyNineCategoryFilter: PropTypes.func.isRequired,
  sixtyPlusCategoryFilter: PropTypes.string,
  setSixtyPlusCategoryFilter: PropTypes.func.isRequired,
  turboCategoryFilter: PropTypes.string,
  setTurboCategoryFilter: PropTypes.func.isRequired,
  setChangeIndividual: PropTypes.func.isRequired,
  isBike: PropTypes.number.isRequired,
};

FilterModal.defaultProps = {
  selectedGenderFilter: null,
  eighteenThirtyCategoryFilter: null,
  thirtyOneFortyFourCategoryFilter: null,
  fortyFiveFiftyNineCategoryFilter: null,
  sixtyPlusCategoryFilter: null,
  turboCategoryFilter: null,
};

export default FilterModal;
